@import './variables.scss';

.daily-tools-container {
  max-width: 1023px;
  min-width: 100%;
  text-align: center;
  z-index: 1;
  @media screen and (max-width: $lg-screen) {
    width: 90%;
  }
  @media screen and (max-width: $md-screen) {
    display: flex;
    justify-content: center;
    min-width: 80%;
  }
  @media screen and (max-width: $xs-screen) {
    width: 100%;
  }
  .daily-tools-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media screen and (max-width: $xs-screen) {
      width: 300px;
      justify-content: flex-start;
    }
  }
}



.daily-tool-link {
  cursor: pointer;
  width: 112px;
  height: 126px;
  margin: 10px;
  overflow: hidden;
  border-radius: 15px;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  @include shadow;
  @media screen and (max-width: $lg-screen) {
    width: 100px;
    height: 115px;
  }
  @media screen and (max-width: $md-screen) {
    width: 110px;
    height: 125px;
    margin: 25px;
  }
  @media screen and (max-width: $sm-screen) {
    width: 100px;
    height: 115px;
    margin: 15px;
  }
  @media screen and (max-width: $xs-screen) {
    width: 80px;
    height: 90px;
    margin: 10px;
  }
  .img {
    border-radius: 15px;
    height: 101%;
    object-fit: cover;
  }
  .logo {
    position: absolute;
    max-width: 80px;

    @media screen and (max-width: $xs-screen) {
      width: 60px;
    }
  }
}

