.modale-container {
  position: fixed;
  top:0;
  width: 100%;
  height: 100vh;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(22, 81, 121, 0.5);
  @media screen and (max-width: $md-screen) {
    width: 100%;
    min-height: 100%;
    height: 100%;
    overflow: auto;
  }
  .modale-content {
    width: 60%;
    min-height: 50%;
    background-color: $white;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    &.profile {
      width: 30%;
      @media screen and (max-width: $md-screen) {
        width: 100%;
        flex-direction: column;
        justify-content: center;
      }
      p {
        font-size: 14px;
        font-weight: bold;
        color: $primary;
      }
    }
    @media screen and (max-width: $md-screen) {
      width: 100%;
      min-height: 100%;
      border-radius: 0px;
      overflow-y: auto;
      justify-content: flex-start;
    }
    .btn-close {
      font-family: Montserrat-Medium, sans-serif;
      font-size: 15px;
      position: absolute;
      right: 0px;
      top: 0px;
      margin: 5px;
      @media screen and (max-width: $md-screen) {
        position: fixed;
      }
      img {
        width: 20px;
        transform: rotate(90deg)
      }
    }
    .error-container {
      width: 100%;
      display: flex;
      justify-content: center;
      .error {
        color: $red;
        text-align: center;
        font-size: 14px;
        background-color: $white;
        margin-left: auto;
        margin-right: auto;
        width: 180px;
        padding: 10px;
        margin-top: -20px;
        border-radius: 10px;
        box-shadow: $shadow; 
        @media screen and (max-width: $md-screen) {
          position: absolute;
          margin-top: 40px;
          &.profile {
            position: relative;
          }
        }
      }
    }   
    .form-container {
      height: 100%;
      display: flex;
      margin: 10px;
      @media screen and (max-width: $md-screen) {
        padding-top: 30px;
        flex-direction: column;
        height: auto;
      }
      .col {
        width: 50%;
        margin: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &.profile {
          width: 100%;
          align-items: center;
        }
        h4 {
          margin-top: 0px;
          color: $primary;
        }
        @media screen and (max-width: $md-screen) {
          width: 100%;
          margin: 0px;
        }
        .drop-container {
          height: 100%;
          border: 1px dashed $secondary;
          margin: 10px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          .preview-img {
            width: 70px;
            height: 70px;
            border-radius: 8px;
            margin: 10px;
          }   
          p {
            text-align: center;
          }
          @media screen and (max-width: $md-screen) {
            margin: 10px;
          }
        }
        .input {
          @include input;
          height: 34px;
          background-color: $white ;
          margin: 10px 10px;
          width: calc(100% - 40px);
        }
        textarea {
          width: calc(100% - 40px);
          max-width: calc(100% - 40px);
          min-width: calc(100% - 40px);
          min-height: 200px;
          padding-top: 10px;
          @media screen and (max-width: $md-screen) {
            min-height: 100px
          }
        }
        .select {
          @include input;
          height: 35px;
          background-color: $white ;
          margin: 10px;
          width: calc(100% - 20px);
        }
      }
    }
    .button-container {
      display: flex;
      justify-content: center;
      margin-bottom: -27px;
      position: absolute;
      width: 100%;
      bottom: 0;
      @media screen and (max-width: $md-screen) {
        margin-top: 10px;
        position: relative;
        margin-bottom: 40px;
        bottom: 30px;
      }
    }
  }
}
