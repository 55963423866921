.login-container {
  width: 100%;
  height: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 2;
  text-align: center;
  .text-error {
    color: $red;
    font-size: 13px;
    min-height: 20px;
  }
  p {
    color: $primary;
    font-size: 13px;
    margin: 0px;
  }
  .logo-octapharma {
    margin: 20px;
    width: 320px;
    z-index: 4;
    @media screen and (max-width: $md-screen) {
      margin: 25px;
      width: 200px;
    }
  }
  .logo-ligne {
    height: 4px;
    width: 100px;
    margin-bottom: 30px;
    background-color: $primary;
  }
  .form-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    @media screen and (max-width: $md-screen) {
      margin: 25px;
      width: 80%;
    }
    .input-login {
      @include input;
      height: 35px;
      background-color: $light-blue ;
      width: 100%;
      margin: 10px 0px;
    }
  }
}