.admin-container {
  padding-top: 100px;
  &.blured-container {
    filter: blur(2px);
    overflow-y:hidden;
    height: 100%;
  }
}

.btn-new-link {
  position: absolute;
  margin: 20px;
  top: 50px;
  left: 0;
  @media screen and (max-width: $md-screen) {
    position: relative;
    margin: 10px;
    top: 0px
  }
}

.btn-admin-container {
  .btn-admin {
    margin: 20px;
  }
  @media screen and (max-width: $md-screen) {
    margin-top: -25px;
    display: flex;
    justify-content: center;
    .btn-admin {
      margin: 10px;
    }
  }
}

.bg-admin {
  opacity: 0.3;
}