@import './variables.scss';

.tools-list-main-container {
  margin-bottom: 60px;
  max-width: 1023px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  z-index: 1;
  @media screen and (max-width: $lg-screen) {
    width: 80%;
  }
  @media screen and (max-width: $xs-screen) {
    display: none;
  }
}

.tools-list-admin-container {
  margin-bottom: 60px;
  max-width: 1023px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  z-index: 1;
  @media screen and (max-width: $lg-screen) {
    width: 80%;
  }
}

.tools-list {
  width: 30%;
  margin-top: 20px;
  margin-right: 3%;
  color: $primary;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $sm-screen) {
    width: 50%;
  }
  @media screen and (max-width: $xs-screen) {
    width: 80%;
    z-index: 1200;
  }
  h4 {
    font-family: Montserrat-Medium, sans-serif;
    margin-top: 10px;
    margin-bottom: 3px;
    float: left;
    text-align: left;
    font-size: 15px;
    @media screen and (max-width: $xs-screen) {
      font-size: 22px;
    }
  }
  .title-bar {
    width: 50px;
    height: 3px;
    background-color: $purple;
  }
  a {
    text-decoration: none;
    color: $primary;
  }
}

.tool-item-container {
  text-align: left;
  min-height: 50px;
  width: 100%;
  margin-right: 25px;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  @media screen and (max-width: $xs-screen) {
    font-size: 22px;
  }
  &:hover {
    background-color: $light-blue;
    -webkit-box-shadow: 1px 8px 34px 5px rgba(0,0,0,0.10);
    -moz-box-shadow: 1px 8px 34px 5px rgba(0,0,0,0.10);
    box-shadow: 1px 8px 34px 5px rgba(0,0,0,0.10);
  }
  .tool-icon {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    background-color: $white;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    @include shadow;
    img {
      width: 41px;
      height: 41px;
    }
  }
  .tool-text {
    display: flex;
    flex-direction: column;
    .tool-name {
      font-family: Montserrat-Medium, sans-serif;
      font-size: 18px;
   }
    span {
      margin-left: 15px;
      font-size: 14px;
      margin-bottom: 0px;
    }
  }
}
