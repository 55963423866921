@mixin shadow {
  -webkit-box-shadow: 0px 13px 25px -10px rgba(0, 0, 0, 0.60);
  -moz-box-shadow: 0px 13px 25px -10px rgba(0,0,0,0.60);
  box-shadow: 0px 13px 25px -10px rgba(0,0,0,0.60);
  &:hover {
    -webkit-box-shadow: 0px 13px 25px -5px rgba(0,0,0,0.6);
    -moz-box-shadow: 0px 13px 25px -5px rgba(0,0,0,0.6);
    box-shadow: 0px 13px 25px -5px rgba(0,0,0,0.6);
  }
}

@mixin btn {
  font-family: Montserrat-Medium, sans-serif;
  padding: 6px 13px;
  border-radius: 20px;
  min-width: 140px;
  z-index: 3;
  font-size: 15px;
  text-align: center;
  border: 0px;
  cursor: pointer;
}

@mixin input {
  border-style: none;
  border: 1px solid $secondary;
  padding-left: 15px;
  border-radius: 20px;
  color:$secondary;
  font-family: Montserrat-Medium, sans-serif;
  font-size: 16px;
  &::placeholder {
    color:$secondary;
  }
}

$primary: rgb(22, 81, 121);
$secondary: #8EAEB6;
$validate-blue: #0200C3;
$red: #D0021B;
$purple: #7B79E6;
$light-grey: #dddddd;
$light-blue: #F3F9FC;
$white: #FFFFFF;

$shadow: 0px 13px 25px -10px rgba(0,0,0,0.60);

$lg-screen:1200px;
$md-screen: 992px;
$sm-screen: 768px; 
$xs-screen: 576px;