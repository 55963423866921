@import "./variables.scss";
.menu-container {
    display: none;
    @media screen and (max-width: $xs-screen) {
        display: flex;
        position: fixed;
        justify-content: center;
        z-index: 1500;
        bottom: -50px;
        height: 100px;
        width: 100%
    }   
    .btn-menu {
        @media screen and (max-width: $xs-screen) {
            width: 100px;
            height: 100px;
            border-radius: 50px;
            background-color: rgb(86, 135, 165);
            @include shadow;
        }
        img {
            margin-top: 16px;
            width: 24px;
            height: 24px;
        }
    }
}

.anim-close {
    bottom: 0;
    -webkit-animation-name: anim-close; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s; /* Safari 4.0 - 8.0 */
    animation-name: anim-close;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    @-webkit-keyframes anim-close {
        from {bottom: 0;}
        to {bottom: -100vh;}
    }
    @keyframes anim-close {
        from {bottom: 0;}
        to {bottom: -100vh;}
    }
}
.anim-open {
    bottom: -100vh;
    -webkit-animation-name: anim-open; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s; /* Safari 4.0 - 8.0 */
    animation-name: anim-open;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    @-webkit-keyframes anim-open {
        from {bottom: -100vh;}
        to {bottom: 0;}
    }
    @keyframes anim-open {
        from {bottom: -100vh;}
        to {bottom: 0;}
    }
}

.menu-list {
    display: none;
    @media screen and (max-width: $xs-screen) {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;        
        align-items: center;
        z-index: 1200;
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(28, 65, 121, 1);
  
        .logo-octapharma {
            display: none;
            @media screen and (max-width: $xs-screen) {
            display: block;
              width: 200px;
              z-index:2500;
              margin: 25px;
            }
        }  
        ul {
            padding: 0;
            padding-top: 10%;
            height: 50%;
            width: 100%;
            list-style: none;
            a {
                cursor: pointer;
                text-decoration: none;
                &:visited {
                    background-color: transparent;
                }
            }
        }
        li {
            margin-bottom: 20px;
            text-transform: uppercase;
            font-weight:bold;
            list-style: none;
            color: $white;
            display: block;
            text-decoration: none !important;
        }
    }
}