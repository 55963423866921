@import './variables.scss';

@mixin random-bg {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 49.5vh;
  z-index: -3;
}

@font-face {
  font-family: Montserrat-Medium;
  src: url(/font/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: Montserrat-Light;
  src: url(/font/Montserrat-Light.ttf) format('truetype');
}

.switch-wrapper {
  position: relative;
  width: 100%;
}
 
.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

h1 {
  font-family: Montserrat-Light, sans-serif;
}

body {
  width: 100%;
  height: 100%;
  font-family: Montserrat-Light, sans-serif;
  background-color: $light-blue;
  cursor: auto;
  overflow: auto;
}

.d-none {
  display: none;
}

button {
  background-color: transparent;
  border: none;
  color: white;
  text-decoration: none;
  margin: 0px 0px;
  cursor: pointer;
}

input:focus {
  outline: none;
  background-color: $light-blue ;
  background-image: none ;
  color: $secondary ;
}

select {
  border:none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover, 
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   background-color: $light-blue ;
//   background-image: none ;
//   color: $secondary ;
// }

:focus {
  outline: none;
  background-color: transparent;
}

.btn-nav-md {
  @media screen and (max-width: $md-screen) {
    display: block;
    margin: 30px 30px;
  }
  @media screen and (max-width: $xs-screen) {
    display: none;
  }
}

.btn-admin-md  {
  @media screen and (max-width: $md-screen) {
    display: flex;
    flex-direction: column;
    margin: 30px 30px;
  }
  @media screen and (max-width: $xs-screen) {
    margin: 0px 10% ;
  }
}

.btn-admin {
  @include shadow;
  @include btn;
  float: right;
  margin:30px 10px;
  color: $primary;
  background-color: $white;
  @media screen and (max-width: $md-screen) {
    float: none;
    position: relative;
    margin: 10px;
  }
}

.btn-blue {
  @include shadow;
  @include btn;
  color: $white;
  background-color: $primary;
  &:focus {
    background-color: $primary;
  }
}

.btn-reset {
  @include btn;
  color: $primary;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
}


.btn-validate {
  @include shadow;
  @include btn;
  margin: 5px;
  color: $white;
  background-color: $validate-blue;
  &.password {
    max-width: 260px;
  }
  &:focus {
    background-color: $validate-blue;
  }
}

.btn-red {
  @include shadow;
  @include btn;
  margin: 10px;
  color: $white;
  background-color: $red;
  &:focus {
    background-color: $red;
  }
}

.main-container {
  min-height: 90vh;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
  @media screen and (max-width: $xs-screen) {
    min-height: 90vh;
  }
  header {
    z-index: 1;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $xs-screen) {
      height: 250px;
    }
  }
  .search-container {
    @media screen and (max-width: $md-screen) {
      margin-top: 40px;
    }
    .search-bar {
      height: 38px;
      width: 350px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 20px;
      @media screen and (max-width: $xs-screen) {
        width: 280px;
      }
      .search-icon {
        width: 20px;
        height: 20px;
        opacity: 0.2;
        margin-top: 4px;
        margin-right: -13px;
        &:hover {
          opacity: 0.4;
        }
      }
      .search-input {
        width: 80%;
        height: 22px;
        margin-top: -2px;
        border: none;
        color: rgb(105, 102, 102);
        font-family: Montserrat-Light, sans-serif;
        font-size: 20px;
        @media screen and (max-width: $md-screen) {
          font-size: 16px;
        }
      }
    }
  }
  .bg-img-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50vh;
    overflow: hidden;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0,$light-blue 100%);
    .logo-octapharma {
      margin: 20px;
      width: 230px;
      float: left;
      @media screen and (max-width: $md-screen) {
        margin: 20px 0px;
        width: 200px;
        z-index:5;
        float: none;
      }
    }
  }
  .bg-1 {
    background-image: url("../../img/bg/bg-1.jpg");
    @include random-bg;
  }
  .bg-2 {
    background-image: url("../../img/bg/bg-2.jpg");
    @include random-bg;
  }
  .bg-3 {
    background-image: url("../../img/bg/bg-3.jpg");
    @include random-bg;
  }
  .bg-4 {
    background-image: url("../../img/bg/bg-4.jpg");
    @include random-bg;
  }
  .bg-5 {
    background-image: url("../../img/bg/bg-5.jpg");
    @include random-bg;
  }
  .bg-6 {
    background-image: url("../../img/bg/bg-6.jpg");
    @include random-bg;
  }
  .bg-7 {
    background-image: url("../../img/bg/bg-7.jpg");
    @include random-bg;
  }
  .bg-8 {
    background-image: url("../../img/bg/bg-8.jpg");
    @include random-bg;
  }
  .bg-9 {
    background-image: url("../../img/bg/bg-9.jpg");
    @include random-bg;
  }
  .bg-10 {
    background-image: url("../../img/bg/bg-10.jpg");
    @include random-bg;
  }
  .bg-11 {
    background-image: url("../../img/bg/bg-11.jpg");
    @include random-bg;
  }
  .bg-12 {
    background-image: url("../../img/bg/bg-12.jpg");
    @include random-bg;
  }
  .bg-13 {
    background-image: url("../../img/bg/bg-13.jpg");
    @include random-bg;
  }
  .bg-14 {
    background-image: url("../../img/bg/bg-14.jpg");
    @include random-bg;
  }
  .bg-15 {
    background-image: url("../../img/bg/bg-15.jpg");
    @include random-bg;
  }
  .bg-16 {
    background-image: url("../../img/bg/bg-16.jpg");
    @include random-bg;
  }
  .bg-17 {
    background-image: url("../../img/bg/bg-17.jpg");
    @include random-bg;
  }
  .bg-18 {
    background-image: url("../../img/bg/bg-18.jpg");
    @include random-bg;
  }
  .bg-19 {
    background-image: url("../../img/bg/bg-19.jpg");
    @include random-bg;
  }
  .bg-20 {
    background-image: url("../../img/bg/bg-20.jpg");
    @include random-bg;
  }
  .bg-21 {
    background-image: url("../../img/bg/bg-21.jpg");
    @include random-bg;
  }
}


